import React, { useState, useEffect } from 'react';
import FileUpload from '../../Upload/FileUpload';

const DataTable = () => {
    const [dados, setDados] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPorPagina] = useState(5);
    const currentDate = new Date().toLocaleDateString();

    useEffect(() => {
        fetchDemoData();
    }, []);

    const fetchDemoData = async () => {
        try {
            const response = await fetch('https://short-swans-rule.loca.lt/api/cotacao', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    base64String: "00000",
                }),
            });
            
            const data = await response.json();
            const drugsData = data.Drugs || [];
            setDados(drugsData);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    };

    // Esta variável agora se baseia diretamente em `dados`, já que `dadosFiltrados` foi removida.
    const dadosPaginados = dados.slice(
        (paginaAtual - 1) * itensPorPagina, 
        paginaAtual * itensPorPagina
    );

    const paginar = (numeroDaPagina) => {
        setPaginaAtual(numeroDaPagina);
    };

    const renderNumerosPagina = () => {
        const numeroDePaginas = Math.ceil(dados.length / itensPorPagina);
        const MAX_PAGINAS_VISIVEIS = 10;
        const inicioIntervalo = Math.floor((paginaAtual - 1) / MAX_PAGINAS_VISIVEIS) * MAX_PAGINAS_VISIVEIS;

        const paginas = [];
        const inicio = Math.max(1, inicioIntervalo);
        const fim = Math.min(inicio + MAX_PAGINAS_VISIVEIS, numeroDePaginas);

        if (inicio > 1) {
            paginas.push(<button className="btn" key="retroceder" onClick={() => paginar(inicio - 1)}>Anterior</button>);
        }

        for (let i = inicio; i <= fim; i++) {
            paginas.push(
                <button
                    className={`btn ${paginaAtual === i ? 'active' : ''}`}
                    key={i}
                    onClick={() => paginar(i)}
                >
                    {i}
                </button>
            );
        }

        if (fim < numeroDePaginas) {
            paginas.push(<button className="btn" key="avançar" onClick={() => paginar(fim)}>Próximo</button>);
        }

        return paginas;
    };

    return (
        <div className="container mt-5">
            <div className="card">
                <FileUpload />
            </div>
            <hr></hr>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Descrição</th>
                        <th scope="col">Quantidade</th>
                        <th scope="col">TUSS</th>
                        {/* <th scope="col">ANVISA</th> */}
                        <th scope="col">Provedor</th>
                        <th scope="col">Provedor</th>
                        <th scope="col">Melhor Preço</th>
                        <th scope="col">Data Simulação</th>
                        <th scope="col">Observações</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {dadosPaginados.map((item, index) => (
                        <tr key={index}>
                            <td>{item.description}</td>
                            <td>{item.qtd}</td>
                            <td>{item.TUSS}</td>
                            {/* <td>{item.ANVISA}</td> */}
                            <td>{item.providers[0].name} - R$: {item.providers[0].price}</td>
                            <td>{item.providers[1].name} - R$: {item.providers[1].price}</td>
                            <td>{item.providers.find(provider => provider.bestPrice).name}</td>
                            <td>{currentDate}</td>
                            <td></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <br></br>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                {renderNumerosPagina()}
            </div>
        </div>
    );
}

export default DataTable;
import React from 'react';
import { Container } from "reactstrap";

import FileUpload from './FileUpload';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Upload = () => {
  document.title = "Dashboard | AI.DATA - Upload Certificate";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="AI.DATA" breadcrumbItem="Trainning Models" />
          <div className="card">
            <FileUpload />
          </div>
        </Container>

      </div>
      
    </React.Fragment>
  );
};

export default Upload;

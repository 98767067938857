import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const DataTable = () => {
    const chartRef1 = useRef(null);
    const chartRef2 = useRef(null);
    const chartRef3 = useRef(null);

    useEffect(() => {
        if (chartRef1.current) {
            new Chart(chartRef1.current, {
                type: 'bar',
                data: {
                    labels: ["2022", "2023", "2024"],
                    datasets: [{
                        label: 'Total itens tabelas',
                        data: [10000, 12456, 34878],
                    }],
                },
            });
        }

        if (chartRef2.current) {
            new Chart(chartRef2.current, {
                type: 'line',
                data: {
                    labels: ["2022", "2023", "2024"],
                    datasets: [{
                        label: 'Modelos cadastrados',
                        data: [2, 10, 1],
                    }],
                },
            });
        }

        if (chartRef3.current) {
            new Chart(chartRef3.current, {
                type: 'polarArea',
                data: {
                    labels: ["Medicamentos", "Materiais", "Outros"],
                    datasets: [{
                        label: 'Agrupamento de itens cadastrados',
                        data: [34878, 24231, 10000],
                    }],
                },
            });
        }
    }, []);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                {/* Card 1 */}
                <div className="card text-white bg-warning mb-3" style={{ width: '24rem', marginRight: '10px', marginTop: '20px'  }}>
                    <div className="card-body">
                        <h5 className="card-title text-fs">Tabelas</h5>
                        <p className="card-text text-fs">Simpro / Brasindice</p>
                    </div>
                </div>

                {/* Card 2 */}
                <div className="card text-white bg-success mb-3" style={{ width: '24rem', marginRight: '10px', marginTop: '20px'  }}>
                    <div className="card-body">
                        <h5 className="card-title text-fs">Medicamentos</h5>
                        <p className="card-text text-fs">34.878</p>
                    </div>
                </div>

                {/* Card 3 */}
                <div className="card text-white bg-success mb-3" style={{ width: '24rem' , marginRight: '10px', marginTop: '20px' }}>
                    <div className="card-body">
                    <h5 className="card-title text-fs">Materias</h5>
                        <p className="card-text text-fs">24.231</p>
                    </div>
                </div>

                {/* Card 4 */}
                <div className="card bg-light mb-3" style={{ width: '24rem', marginRight: '10px', marginTop: '20px' }}>
                    <div className="card-body">
                        <h5 className="card-title text-fs">Status API's</h5>
                        <p className="card-text text-fs">ATIVO</p>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <div style={{ width: '700px', marginRight: '10px' }}>
                    <canvas ref={chartRef1} id="chart1"></canvas>
                </div>
                <div style={{ width: '700px' }}>
                    <canvas ref={chartRef2} id="chart2"></canvas>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '700px' }}>
                    <canvas ref={chartRef3} id="chart3"></canvas>
                </div>
            </div>
        </div>
    );
};

export default DataTable;

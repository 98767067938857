import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";
import Upload from "../Pages/Upload/Upload";

import ManCertificates from "../Pages/Management/Certificates";
import ManCredentials from "../Pages/Management/Credentials";
import ManAiData from "../Pages/Management/Aidata";

import ApiAuth from "../Pages/ApiReference/Authentication";
import ApiUpload from "../Pages/ApiReference/Upload";
import ApiCallback from "../Pages/ApiReference/Callback";
import ApiOffLine from "../Pages/ApiReference/Offline";
import ApiPriceInsight from "../Pages/ApiReference/PriceInsight";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import PriceInsightLote from "../Pages/PriceInsight/Lote";
import PriceInsightFind from "../Pages/PriceInsight/Find";
import PriceInsightMan from "../Pages/PriceInsight/Management";


const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/upload", component: <Upload /> },
  { path: "/apidoc/authentication", component: <ApiAuth /> },
  { path: "/apidoc/upload", component: <ApiUpload /> },
  { path: "/apidoc/callback", component: <ApiCallback /> },
  { path: "/apidoc/offline", component: <ApiOffLine /> },
  { path: "/apidoc/priceinsight", component: <ApiPriceInsight />},
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/management/certificates", component: <ManCertificates /> },
  { path: "/management/credentials", component: <ManCredentials /> },
  { path: "/management/aidata", component: <ManAiData /> },
  { path: "/priceinsight/lote", component: <PriceInsightLote /> },
  { path: "/priceinsight/find", component: <PriceInsightFind /> },
  { path: "/priceinsight/management", component: <PriceInsightMan /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };

import React from 'react';
import { Container } from "reactstrap";

import DataTable from './DataTable';
import '../index.css';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const Simulations = () => {
  document.title = "Dash | AI.DATA - Dashboard";
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="AI.PRICEINSIGHTS" breadcrumbItem="PMC management" />
          <div className="card">
            <DataTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Simulations;

import React from 'react';
import { Container } from "reactstrap";
import '../index.css';

import AiDataTable from './AiDataTable';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const Simulations = () => {
  document.title = "Dash | AI.DATA - Security data store";
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="AI.DATA" breadcrumbItem="Security data store" />
          <div className="card">
            <AiDataTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Simulations;

import React, { useState } from 'react';
import AuthService from "../../../components/AuthService";

const authService = new AuthService();

const getToken = () => {
    return localStorage.getItem('token');
};

const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

const FileUpload = ({refreshTickets }) => {
    const [tickets, setTickets] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [modalMensagem, setModalMensagem] = useState('');
    const [classMsgError, setClassMsgError] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleSubmit = async () => {
        setClassMsgError(true);

        try {
            setIsUploading(true);
            
            const token = getToken();

            const base64File = await fileToBase64(selectedFile);

            const payload = { 
                base64: base64File 
            };
            const response = await fetch(`/api/pmc/upload`, {
            // const response = await fetch(`https://aidata-monitoring.cloud/pmc/upload`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.status === 401) {
                const newToken = await authService.getToken();
                localStorage.setItem("token", newToken);
                return handleSubmit();
            }

            const data = await response.json();

            if(data.uuid !== null && data.uuid !== undefined && data.uuid !== ''){
                const localList = refreshTickets();

                setClassMsgError(false);
                setModalMensagem(`Arquivo enviado com sucesso para processamento.`);
                showCustomModal();

                setIsUploading(false);
            
                const newTicket = { id: data.uuid, status: 'Arquivo em processamento' };

                setTickets(localList => [...localList, newTicket]);

                sessionStorage.setItem("tickets", JSON.stringify([...localList, newTicket]));
            
                document.getElementById('formFile').value = null;
                setSelectedFile(null);

                refreshTickets();
            } 

        } catch (error) {
            console.error('Erro ao buscar dados:', error);

            setIsModalVisible(true);
            setModalMensagem(`Falha no upload. Tente novamente mais tarde.`);
            showCustomModal();
            setIsUploading(false);
        }
    }
    
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const showCustomModal = () => {
        const modal = document.getElementById('customModal');
        modal.classList.add('show');
        modal.style.display = 'block';
    };

    const hideCustomModal = () => {
        const modal = document.getElementById('customModal');
        modal.classList.remove('show');
        modal.style.display = 'none';
    };

    return (
        <div className="card">
            <div className="mb-3 d-flex align-items-center">
                <input className="form-control me-3" type="file" id="formFile" onChange={handleFileChange} />
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit}
                    disabled={isUploading || !selectedFile}
                >
                    {isUploading ? 'Uploading...' : 'Upload'}
                </button>
            </div>
            <div className={`modal fade ${modalMensagem && 'show'}`} id="customModal" tabIndex="-1" aria-labelledby="customModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="customModalLabel">Oops!</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={hideCustomModal}></button>
                        </div>
                        <div className="modal-body">
                            {modalMensagem && <div className={`alert ${classMsgError ? 'alert-danger' : 'alert-success'}`} role="alert">{modalMensagem}</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={hideCustomModal}>Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileUpload;
import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

// Login Redux States
import {
    GET_DEMO_DATA,
    POST_UPLOAD,
} from "./actionTypes"
import {
    getDemoDataSuccess,
    getDemoDataFail,
    getUploadSuccess,
    getUploadFail,
} from "./actions"
                                      
import { getDemoData, postUpload } from "../../helpers/fakebackend_helper";

function* fetchDemoData() {
  try {
    const response = yield call(getDemoData)
    yield put(getDemoDataSuccess(response))
  } catch (error) {
    yield put(getDemoDataFail(error))
  }
}
                                      
export function* watchFetchDemoData() {
  yield takeEvery(GET_DEMO_DATA, fetchDemoData);
}
                                      
function* fetchUploadData({ payload }) {
  try {
    const response = yield call(postUpload, payload);
    yield put(getUploadSuccess(response.data));
    return response.data;
  } catch (error) {
    yield put(getUploadFail(error));
    throw error;
  }
}

export function* watchFetchUploadData() {
  yield takeEvery(POST_UPLOAD, fetchUploadData);
}

function* demoSaga() {
  yield all([fork(watchFetchDemoData), fork(watchFetchUploadData)]);
}

export default demoSaga;
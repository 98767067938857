import React, { useState } from 'react';

const AiDataTable = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  return (
    <div className="container mt-5">
      <table className="table table-striped">
        <thead>
            <tr>
                <th scope="col">Id</th>
                <th scope="col">API</th>
                <th scope="col">User</th>
                {/* <th scope="col">Password</th> */}
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody className="table-group-divider">
            <tr>
                <td>1</td>
                <td>AI.DATA</td>
                <td>saude.reembolso</td>
                {/* <td>
                  {showPassword ? (
                    <input type="text" value="123456" readOnly />
                  ) : (
                    <input type="password" value="123456" readOnly />
                  )}
                  <button className="btn btn-warning" onClick={togglePasswordVisibility} style={{ '--bs-btn-padding-y': '.25rem', '--bs-btn-padding-x': '.5rem', '--bs-btn-font-size': '.75rem' }}>
                    {showPassword ? 'Hide' : 'Show'}
                  </button>
                </td> */}
                <td>
                  <button className="btn btn-primary">Renew</button>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>AI.DOC</td>
                <td>saude.reembolso</td>
                {/* <td>
                  {showPassword ? (
                    <input type="text" value="123456" readOnly />
                  ) : (
                    <input type="password" value="123456" readOnly />
                  )}
                  <button className="btn btn-warning" onClick={togglePasswordVisibility} style={{ '--bs-btn-padding-y': '.25rem', '--bs-btn-padding-x': '.5rem', '--bs-btn-font-size': '.75rem' }}>
                    {showPassword ? 'Hide' : 'Show'}
                  </button>
                </td> */}
                <td>
                  <button className="btn btn-primary">Renew</button>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>PMC Quote</td>
                <td>saude.reembolso</td>
                {/* <td>
                  {showPassword ? (
                    <input type="text" value="123456" readOnly />
                  ) : (
                    <input type="password" value="123456" readOnly />
                  )}
                  <button className="btn btn-warning" onClick={togglePasswordVisibility} style={{ '--bs-btn-padding-y': '.25rem', '--bs-btn-padding-x': '.5rem', '--bs-btn-font-size': '.75rem' }}>
                    {showPassword ? 'Hide' : 'Show'}
                  </button>
                </td> */}
                <td>
                  <button className="btn btn-primary">Renew</button>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AiDataTable;
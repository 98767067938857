import React, { useState } from 'react';
import FileUpload from '../../Upload/FileUpload';

const CredentialDataTable = () => {
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    certificate: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Configurando o objeto de opções da solicitação
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: formData.name, password: formData.password }),
    };

    try {
      const response = await fetch('https://inviting-imp-polite.ngrok-free.app/api/gravar-arquivo', requestOptions);
      const responseData = await response.json();
      console.log(responseData);
      alert('Form submitted successfully');
      setFormData({
        name: '',
        password: '',
        certificate: null,
      });
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Input Name:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Input Password:</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <br></br>

        <div className="form-group">
          <FileUpload/>
        </div>
        <br></br>

        <div className="form-group">
          <button className="btn btn-primary" type="submit">Salvar</button>
        </div>
        <br></br>
      </form>
    </div>
  );
}

export default CredentialDataTable;
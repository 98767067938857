class AuthService {
    async getToken() {

        const obj = JSON.parse(localStorage.getItem("authUser"));

        try {
            const response = await fetch(process.env.REACT_APP_TOKEN_SERVICE_URL + '/authenticate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: obj.email,
                    password: obj.uid
                })
            });

            const data = await response.json();
            if (data && data.jwttoken) {
                return data.jwttoken;
            } else {
                throw new Error('Token não encontrado na resposta.');
            }
        } catch (error) {
            console.error('Erro ao obter token:', error);
            throw error;
        }
    }
}

export default AuthService;

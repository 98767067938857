import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [setUploadError] = useState(null);
    const [modalMessage, setModalMessage] = useState('');
    const [modalMessageType, setModalMessageType] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setUploadError(null);
    }

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const hideModal = () => {
        const modal = document.getElementById('uploadModal');
        modal.classList.remove('show');
        modal.style.display = 'none';
    };

    const handleUploadSuccess = () => {
        setModalMessage('Upload bem-sucedido!');
        setModalMessageType('success');
        const modal = document.getElementById('uploadModal');
        modal.classList.add('show');
        modal.style.display = 'block';
    };

    const handleUploadFailure = (msg) => {
        setModalMessage(msg);
        setModalMessageType('error');
        const modal = document.getElementById('uploadModal');
        modal.classList.add('show');
        modal.style.display = 'block';
    };

    const handleSubmit = async () => {
        if (!selectedFile) {
            handleUploadFailure('Por favor, selecione um arquivo!');
            return;
        }

        try {
            const userString = localStorage.getItem("authUser");

            if (userString) {
                const userObject = JSON.parse(userString);
            
                setIsUploading(true);
                
                const base64 = await fileToBase64(selectedFile);
                const payload = { 
                    "userEmail": userObject.email,
                    base64Document: base64 };

                const response = await axios.post(process.env.REACT_APP_TOKEN_SERVICE_URL + '/pmc/upload', payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                });

                console.log('Upload bem-sucedido:', response.data);
                handleUploadSuccess();
            }
        } catch (error) {
            console.error('Error:', error);
            setUploadError('Falha no upload.');
            handleUploadFailure('Falha no upload. Tente novamente mais tarde.');
        } finally {
            setIsUploading(false);
            setSelectedFile(null);
        }
    }

    return (
        <div className="container mt-5">
            <div className="mb-3 d-flex align-items-center">
                <input className="form-control me-3" type="file" id="formFile" onChange={handleFileChange} />
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit}
                    disabled={isUploading}
                >
                    {isUploading ? 'Uploading...' : 'Upload'}
                </button>
                {/* {uploadError && <p className="error">{uploadError}</p>} */}
            </div>

            <div className={`modal fade ${modalMessage && 'show'}`} id="uploadModal" tabIndex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="uploadModalLabel">Oops!</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}></button>
                        </div>
                        <div className={`modal-body ${modalMessageType === 'success' ? 'alert alert-success' : 'alert alert-danger'}`} role="alert">
                            {modalMessage}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={hideModal}>Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default FileUpload;
import React, { useState, useEffect } from 'react';
import FileUpload from './FileUpload';
import AuthService from "../../../components/AuthService";
import GerarPlanilhaExcel from './GerarPlanilhaExcel';

const authService = new AuthService();

const getToken = () => {
    return localStorage.getItem('token');
};

const MainComponent = () => {
    const [tickets, setTickets] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        refreshTickets();
        const interval = setInterval(() => {
            checkTicketStatus();

            setIsModalVisible(false);

        }, 10000);
                        
        return () => clearInterval(interval);
    }, []);

    const checkTicketStatus = async () => {
        const localList = refreshTickets();

        const token = getToken();

        await Promise.all(localList.map(async ticket => {

            if (ticket.status === "Arquivo em processamento") {
                try {
                    const response = await fetch(`/api/pmc/findticket`, {
                    // const response = await fetch(`https://aidata-monitoring.cloud/pmc/find_ticket_upload/${ticket.id}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify(ticket.id),
                    });
            
                    console.log('response', response.status);

                    if (response.status === 200) {
                        response.json().then(data => {
                            const drugs = data.drugs;    
                            const urlLink = GerarPlanilhaExcel({ dados: drugs });  
                        
                            setTicketSession(ticket, 'Finalizado', urlLink, removeTicket, localList, setTickets);
                        });

                        return true;
                    }else{

                        if(response.status === 404){
                            setTicketSession(ticket, 'Documento Inválido', null, removeTicket, localList, setTickets);
                        }

                        if (response.status === 401) {
                            const newToken = await authService.getToken();
                            localStorage.setItem("token", newToken);
                            return checkTicketStatus();
                        }
                    }


                } catch (error) {
                    console.error('Erro ao verificar status do ticket:', error);
                }
            }
            return ticket;
        }));
        
    };

    const refreshTickets = () => {
        const storedTickets = JSON.parse(sessionStorage.getItem('tickets')) || [];
        setTickets(storedTickets);
        return storedTickets;
    };

    const removeTicket = (localList, idToRemove) => {
        const updatedTickets = localList.filter(ticket => ticket.id !== idToRemove);
        sessionStorage.setItem("tickets", JSON.stringify(updatedTickets));
        refreshTickets();
        return updatedTickets;
    };
    
    return (
        <div className="container mt-5">
            <FileUpload refreshTickets={refreshTickets} />
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Status</th>
                        <th scope="col">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.map(ticket => (
                        <tr key={ticket.id}>
                            <td>{ticket.id}</td>
                            <td>{ticket.status}</td>
                            <td>
                                {ticket.status === 'Finalizado' && (
                                    <a
                                        href={`${ticket.url}`}
                                    >
                                        Download
                                    </a>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MainComponent;

function setTicketSession(ticket, status, urlLink, removeTicket, localList, setTickets) {
    const newTicket = { id: ticket.id, status: status, url: urlLink };

    const listWihtoutTicket = removeTicket(localList, ticket.id);

    const listTickets = [...listWihtoutTicket, newTicket];
    setTickets(listTickets);

    sessionStorage.setItem("tickets", JSON.stringify(listTickets));
}

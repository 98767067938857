import {
  GET_DEMO_DATA,
  GET_DEMO_DATA_SUCCESS,
  GET_DEMO_DATA_FAIL,
  POST_UPLOAD,
  POST_UPLOAD_SUCCESS,
  POST_UPLOAD_FAIL,
} from "./actionTypes";

export const getDemoData = () => ({
  type: GET_DEMO_DATA,
});

export const getDemoDataSuccess = data => ({
  type: GET_DEMO_DATA_SUCCESS,
  payload: data,
});

export const getDemoDataFail = error => ({
  type: GET_DEMO_DATA_FAIL,
  payload: error,
});

// UPLOAD
export const postUpload = (formData) => ({
  type: POST_UPLOAD,
  payload: formData,
});

export const getUploadSuccess = data => ({
  type: POST_UPLOAD_SUCCESS,
  payload: data,
});

export const getUploadFail = error => ({
  type: POST_UPLOAD_FAIL,
  payload: error,
});
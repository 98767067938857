import React from 'react';
import { Container } from "reactstrap";

import ApiOffline from './ApiReference';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const Simulations = () => {
  document.title = "Dashboard | AI.DATA - Api Reference Offline";
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="AI.DATA" breadcrumbItem="Offline" />
          <div className="card">
            <ApiOffline />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Simulations;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthService from "../../../components/AuthService";

const authService = new AuthService();

const getToken = () => {
    return localStorage.getItem('token');
};

const DataTable = () => {
    const ITEMS_POR_PAGINA = 50;
    
    const [dados, setDados] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [chavePesquisa, setChavePesquisa] = useState('');
    const [valorPesquisa, setValorPesquisa] = useState('');
    const [modalMensagem, setModalMensagem] = useState('');
    const [carregando, setCarregando] = useState(false);

    const fetchDemoData = async (filterType, attempt = 1) => {
        try {
            setCarregando(true);
            const token = getToken();

            const bodyParams = {
                [chavePesquisa]: valorPesquisa
            };
    
            if (filterType !== null) {
                bodyParams.filterType = filterType;
            }
    
            const response = await fetch(`/api/pmc/search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(bodyParams),
            });
    
            if (response.status === 401 && attempt === 1) {
                const newToken = await authService.getToken();
                localStorage.setItem("token", newToken);
                return fetchDemoData(filterType, 2);
            }

            const data = await response.json();
            const drugsData = data.drugs || [];
            setDados(drugsData);
            setCarregando(false);    
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setCarregando(false);
        }
    };

    const handleChavePesquisaChange = (event) => {
        setChavePesquisa(event.target.value);
    };

    const handleValorPesquisaChange = (event) => {
        setValorPesquisa(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isValidInput()) {
            return;
        }

        const checkbox = document.getElementById('filterType');
        const filterType = checkbox.checked ? 'BEST_PRICE' : 'DRUGS';

        fetchDemoData(filterType, null);
    };

    const isValidInput = () => {
        if (chavePesquisa === '') {
            setModalMensagem('Por favor, selecione uma chave de pesquisa antes de prosseguir.');
            showCustomModal();
            return false;
        }
    
        if ((chavePesquisa === 'tiss' && !/^\d{10}$/.test(valorPesquisa)) ||
            (chavePesquisa === 'tuss' && !/^\d{8}$/.test(valorPesquisa))) {
            
            setModalMensagem(`Por favor, insira um valor válido para ${chavePesquisa === 'tiss' ? 'TISS (10 caracteres numéricos)' : 'TUSS (8 caracteres numéricos)'}`);
            showCustomModal();
            return false;
        }
        return true;
    };
    

    const paginar = (numeroDaPagina) => {
        setPaginaAtual(numeroDaPagina);
    };

    const renderNumerosPagina = () => {
        const numeroDePaginas = Math.ceil(dados.length / ITEMS_POR_PAGINA);
        const MAX_PAGINAS_VISIVEIS = 10;
        const inicioIntervalo = Math.floor((paginaAtual - 1) / MAX_PAGINAS_VISIVEIS) * MAX_PAGINAS_VISIVEIS;
    
        const paginas = [];
        const inicio = Math.max(1, inicioIntervalo);
        const fim = Math.min(inicio + MAX_PAGINAS_VISIVEIS - 1, numeroDePaginas);
    
        if (inicio > 1) {
            paginas.push(<button className="btn" key="retroceder" onClick={() => paginar(inicio - 1)}>Anterior</button>);
        }
    
        for (let i = inicio; i <= fim; i++) {
            paginas.push(
                <button
                    className={`btn ${paginaAtual === i ? 'active' : ''}`}
                    key={i}
                    onClick={() => paginar(i)}
                >
                    {i}
                </button>
            );
        }
    
        if (fim < numeroDePaginas) {
            paginas.push(<button className="btn" key="avançar" onClick={() => paginar(fim + 1)}>Próximo</button>);
        }
    
        return paginas;
    };    

    const showCustomModal = () => {
        const modal = document.getElementById('customModal');
        modal.classList.add('show');
        modal.style.display = 'block';
    };

    const hideCustomModal = () => {
        const modal = document.getElementById('customModal');
        modal.classList.remove('show');
        modal.style.display = 'none';
    };

    return (
        <div className="container mt-5">
            <div className="card">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3 d-flex align-items-center">
                        <select className="form-select me-3" aria-label="Default select example" onChange={handleChavePesquisaChange}>
                            <option value="">Selecione o tipo da pesquisa</option>
                            <option value="tiss">TISS</option>
                            <option value="tuss">TUSS</option>
                            <option value="description">DESCRIÇÃO</option>
                        </select>
                        <input type="text" className="form-control me-3" id="exampleFormControlInput1" onChange={handleValorPesquisaChange} />
                        <button type="submit" className="btn btn-success">Pesquisar</button>
                    </div>
                </form>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="BEST_PRICE" id="filterType"/>
                    <label className="form-check-label" htmlFor="flexCheckDisabled">
                        Melhor Preço
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDisabled" disabled />
                    <label className="form-check-label" htmlFor="flexCheckDisabled">
                        Tabela Materiais
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" defaultChecked disabled />
                    <label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
                        Tabela Medicamentos
                    </label>
                </div>
                <br />
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">TISS</th>
                        <th scope="col">TUSS</th>
                        <th scope="col">Descrição</th>
                        <th scope="col">Fabricante</th>
                        <th scope="col">Provedor</th>
                        <th scope="col">Preço</th>
                    </tr>
                </thead>
                {carregando && (
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
                {!carregando && (
                    <tbody className="table-group-divider">
                        {dados.length > 0 ? (
                            dados
                            .slice((paginaAtual - 1) * ITEMS_POR_PAGINA, paginaAtual * ITEMS_POR_PAGINA)
                            .map((item, index) => (
                                <tr key={index}>
                                    <td>{item.tiss}</td>
                                    <td>{item.tuss}</td>
                                    <td>{item.description}</td>
                                    <td>{item.manufacturer}</td>
                                    <td>{item.provider}</td>
                                    <td>{item.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">Nenhum dado disponível</td>
                            </tr>
                        )}
                    </tbody>
                )}

            </table>
            <br />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                {renderNumerosPagina()}
            </div>

            <div className={`modal fade ${modalMensagem && 'show'}`} id="customModal" tabIndex="-1" aria-labelledby="customModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="customModalLabel">Oops!</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={hideCustomModal}></button>
                        </div>
                        <div className="modal-body">
                            {modalMensagem && <div className="alert alert-danger" role="alert">{modalMensagem}</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={hideCustomModal}>Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataTable;
import React, { useState } from 'react';

const CertificatesDataTable = () => {
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    certificate: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Configurando o objeto de opções da solicitação
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: formData.name, password: formData.password }),
    };

    try {
      const response = await fetch('https://inviting-imp-polite.ngrok-free.app/api/gravar-arquivo', requestOptions);
      const responseData = await response.json();
      console.log(responseData);
      alert('Form submitted successfully');
      setFormData({
        name: '',
        password: '',
        certificate: null,
      });
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const [showPassword, setShowPassword, setShowTable] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const toggleTableVisibility = () => {
    setShowTable(prevState => !prevState);
  };
  
  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Input Name:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange} required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Input Password:</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange} required
          />
        </div>
        <div className="form-group">
          <label htmlFor="expirationDate">Expiration Date:</label>
          <input
            type="date"
            className="form-control"
            id="expirationDate"
            name="expirationDate"
            onChange={handleChange} required
          />
        </div>
        <br></br>
        <div className="mb-3">
          <input type="file" className="form-control" aria-label="Upload Certificate" required />
        </div>
        <br></br>
        <div className="form-group">
          <button className="btn btn-primary" type="submit" onClick={toggleTableVisibility}>Submit</button>
        </div>
        <br></br>
      </form>
      {showPassword ? (
        <table className="table table-striped">
          <thead>
              <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Password</th>
                  <th scope="col">Expiration date</th>
                  <th scope="col">Actions</th>
              </tr>
          </thead>
          <tbody className="table-group-divider">
              <tr>
                  <td>1</td>
                  <td>Porto-Financeiro</td>
                  <td>
                    {showPassword ? (
                      <input type="text" value="123456" readOnly />
                    ) : (
                      <input type="password" value="123456" readOnly />
                    )}
                    <button className="btn btn-warning" onClick={togglePasswordVisibility} style={{ '--bs-btn-padding-y': '.25rem', '--bs-btn-padding-x': '.5rem', '--bs-btn-font-size': '.75rem' }}>
                      {showPassword ? 'Hide' : 'Show'}
                    </button>
                  </td>
                  <td>2021-12-31</td>
                  <td>
                    <button className="btn btn-primary">Edit</button>
                    <button className="btn btn-danger">Enabled</button>
                  </td>
              </tr>
          </tbody>
        </table>
      ): (
        <table className="table table-striped">
          <thead>
              <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Password</th>
                  <th scope="col">Expiration date</th>
                  <th scope="col">Actions</th>
              </tr>
          </thead>
        </table>
      )}
    </div>
  );
}

export default CertificatesDataTable;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./i18n";
import { PrimeReactProvider } from 'primereact/api';

import {configureStore} from "./store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const value = {
  cssTransition: false,
};

root.render(
  <PrimeReactProvider value={value}>
    <Provider store={configureStore({})}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
  </PrimeReactProvider>
);
reportWebVitals();
// serviceWorker.unregister();


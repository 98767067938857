const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        badgeValue: ""
    },
    {
        label: "Price Insights",
        icon: "mdi mdi-file-find",
        subItem: [
            { sublabel: "Consulta por lote", link: "/priceinsight/lote" },
            { sublabel: "Consulta unitária", link: "/priceinsight/find" }
        ]
    },
    {
        label: "Management",
        icon: "ri-secure-payment-fill",
        subItem: [
            { sublabel: "Cygnus credentials", link: "/management/aidata" },
            { sublabel: "Certificates", link: "/management/certificates" },
            { sublabel: "Credentials", link: "/management/credentials" },
        ]
    },
    {
        label: "Trainning Models",
        icon: "ri-upload-2-fill",
        url: "/upload",
        badgeValue: ""
    },
    {
        label: "API Reference",
        icon: "ri-file-info-fill",
        subItem: [
            { sublabel: "Authentication", link: "/apidoc/authentication" },
            { sublabel: "Upload documents", link: "/apidoc/upload" },
            { sublabel: "Callback - Models", link: "/apidoc/callback" },
            { sublabel: "Offline - Process", link: "/apidoc/offline" },
            { sublabel: "Price Insight", link: "/apidoc/priceinsight" },
        ]
    }
]
export default SidebarData;
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import '../index.css';

const ApiAuth = (props) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch('/posts/authentication.md')
          .then((response) => response.text())
          .then((text) => setContent(text))
          .catch((err) => console.error("Failed to load markdown content", err));
      }, []);

    return (
        <div className="container mt-5">
            <ReactMarkdown>{content}</ReactMarkdown>;
        </div>
    );
}

export default ApiAuth;
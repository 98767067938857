import * as XLSX from 'xlsx';

const GerarPlanilhaExcel = ({dados}) => {
    try {
        if (!dados) {
            console.error('Erro ao gerar planilha Excel: dados está indefinido');
            return null;
        }

        const worksheet = XLSX.utils.json_to_sheet(dados);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);

        return url;
    } catch (error) {
        console.error('Erro ao gerar planilha Excel:', error);
        return null;
    }
};

export default GerarPlanilhaExcel;
